import notifications from './notifications';

const request = require('superagent');

request.http2 = true;

const routes = {
  post: 'https://cors-anywhere.herokuapp.com/https://api.mailjet.com/v3.1/send',
};

const toString = (data) => `New message from ${data.firstName} ${data.lastName} - message:  ${data.message} - contact : ${data.phone} - ${data.email}`;

const send = (data) => (
  request.post(routes.post)
    .send(`{
            "Messages": [
              {
                "From": {
                  "Email": "stefbrol@hotmail.com",
                  "Name": "Ading Contact"
                },
                "To": [
                  {
                    "Email": "info@ading.be",
                    "Name": "Info-Ading"
                  }
                ],
                "Subject": "New message from ${data.lastName}",
                "TextPart": "${toString(data)}",
                "CustomID": "AppGettingStartedTest"
              }
            ]
          }`)
    .set('Accept', 'application/json')
    .set('Access-Control-Allow-Origin', '*')
    .set('dataType', 'application/json')
    .auth('7c8817e2fb1436cbabf9fcf8a80dc9e3', 'b8f6a68bf343a283191388e904f73a1f')
    .then(() => null)
);

export default {
  send: notifications.wrapMethod(send),
};
