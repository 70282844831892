import styled from 'styled-components';
import React from 'react';
import Paragraph from '../controls/paragraph';
import { media } from '../../constants/theme';

const Headline = styled.div`
  font-weight: 500;
`;

const Container = styled.div`
    width: 100%;
    max-width: 1140px;
    margin: 0 auto;
    ${Headline} {
      font-size: 1.5rem;
    }
    & div {
      font-size: 1rem;
      margin: 2rem auto;
      text-align: center;
      width: 80%;
    }
    @media (min-width: ${media.small}px) {
      ${Headline} {
        font-size: 1.75rem;
      }
      & div {
        margin: 3rem auto;
      }
    }
    @media (min-width: ${media.medium}px) {
      ${Headline} {
        font-size: 1.75rem;
      }
      & div {
        margin: 4rem auto;
      }
    }
    @media (min-width: ${media.large}px) {
      ${Headline} {
        font-size: 2.25rem;
      }
      & div {
        margin: 5rem auto;
      }
    }
`;

const Title = ({ title, description }) => (
  <Container>
    <Headline>{title}</Headline>
    <div>
      <Paragraph>{description}</Paragraph>
    </div>
  </Container>
);

export default Title;
