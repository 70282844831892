import React, { useState } from 'react';
import styled from 'styled-components';
import { I18n } from '../i18n';
import { logo } from '../../assets/images';
import { colors } from '../../constants/theme';
import { version } from '../../../package.json';
import Modal from './modal';
import Changelog from './changelog';

const Container = styled.div`
  background-color: white;
  bottom: 0;
  display: inline-flex
  height: fit-content;
  position: relative;
  top: 60px;
  padding: 2rem 0;
  width: 100%;
`;

const ShowChangelog = styled.div`
  color: inherit;
  cursor: pointer;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  width: fit-content;
`;

const Logo = styled.img`
  height: 1.5rem;
  width: 1.5rem;
`;

const Copyright = styled.div`
  color: ${colors.textDimmed};
  float: right;
  font-size: 0.75rem;
  text-decoration: inherit;
  padding: 0.25rem 0.5rem;
`;

const EasterEgg = styled.div`
  border: 1px solid white;
  border-radius: 4px;
  color: white;
  left: 50%;
  padding: 4px;
  position: relative;
  top: 1.5rem;
  transform: translateX(-50%);
  transition: all 1.5s ease;
  &>a{
    color: white;
    text-decoration: inherit;
    transition: all 1.5s ease;
  }
  &:hover {
    border: 1px solid ${colors.text};
    &>a{
      color: ${colors.textDimmed};
    }
  }
`;

const Footer = () => {
  const [open, setOpen] = useState(false);
  const onClose = () => setOpen(false);

  return [
    <Container {...{ key: 'footer' }}>
      <ShowChangelog {...{ onClick: () => setOpen(true) }}>
        <I18n {...{ id: 'footer.poweredBy.logo', attrs: ['alt', 'title'] }}>
          {(props) => <Logo {...{ src: logo, alt: 'ading bv', title: 'ading bv', ...props }} />}
        </I18n>
        <Copyright>
          <span>&nbsp;</span>
          <I18n {...{ id: 'footer.poweredBy', model: { year: new Date().getFullYear(), version } }}>Powered by © ading bv [year:2019] @ version : [version:0.0.1]</I18n>
        </Copyright>
      </ShowChangelog>
      <EasterEgg>
        <a {...{ href: 'https://www.youtube.com/watch?v=dQw4w9WgXcQ', target: '_blanc' }}>
          <i {...{ className: 'fas fa-fingerprint' }} />
          <span>&nbsp;Admin login</span>
        </a>
      </EasterEgg>
    </Container>,
    <Modal {...{ key: 'changlog', open, onClose, title: 'Changelog', content: <Changelog {...{ onClose }} /> }} />,
  ];
};

export default Footer;
