import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Snackbar } from '@material-ui/core/';
import MuiAlert from '@material-ui/lab/Alert';
import { notificationActions } from '../../actions';

class SnackBar extends PureComponent {
  render() {
    const { notifications } = this.props;
    const notification = (notifications || []).find((x) => !x.isHandled && x.showAsSnackBar);
    // Return when no notification needs to be shown
    if (!notification) { return null; }

    const { id: key, autoHideDuration, message, severity } = notification;
    const anchorOrigin = { vertical: 'bottom', horizontal: 'center' };
    const onClose = () => notificationActions.handle(key);
    return (
      <Snackbar {...{ key, anchorOrigin, open: true, autoHideDuration, onClose }}>
        <MuiAlert {...{ elevation: 6, variant: 'filled', severity, onClose }}>{message}</MuiAlert>
      </Snackbar>
    );
  }
}

export default connect((state) => state.notification)(SnackBar);
