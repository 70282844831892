import { get } from 'superagent';
import store from '../store';
import { actions } from '../constants';

const routes = {
  load: 'data/profiles/{name}.json',
};

const names = ['BartVanHoutte-ADING', 'HavermansStef', 'OlivierVanHoutte-ADING'];

const load = () => (
  Promise.all(names.map((key) => get(routes.load.replace('{name}', key))
    .then(({ body }) => ({ ...body, key }))
    .catch(() => null)))
    .then((profiles) => store.dispatch({ type: actions.profile.load, data: profiles.filter((p) => !!p) }))
);

export default {
  load,
};

export {
  load,
};
