import React, { PureComponent } from 'react';
import { Formik, Field, Form } from 'formik';
import styled from 'styled-components';
import { TextField } from 'formik-material-ui';
import { Button } from '@material-ui/core';
import * as Yup from 'yup';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { contactActions, notificationActions } from '../../../actions';
import { matUiTheme } from '../../../constants/theme';
import { severities } from '../../../constants';

const fields = [
  { key: 0, name: 'firstName', label: 'First Name', default: '', validation: Yup.string().required('First Name is required'), type: 'text' },
  { key: 1, name: 'lastName', label: 'Last Name', default: '', validation: Yup.string().required('Last Name is required'), type: 'text' },
  { key: 2, name: 'email', label: 'Mail', default: '', validation: Yup.string().email('Email is invalid').required('Email is required'), type: 'text' },
  { key: 3, name: 'phone', label: 'Phone', default: '', validation: Yup.string().min(6, 'phone must be at least 6 characters'), type: 'text' },
  { key: 4, name: 'message', label: 'Message', default: '', validation: Yup.string().required('Message is required'), type: 'text', multiline: true, rows: 5 },
];

const Container = styled.div`
  align-items:center;
  height: 100%;
  padding: 30px;
`;

const StyledForm = styled(Form)`
  padding: 10px;
  margin-bottom: 10%;
  text-align: center;
`;

const ButtonContainer = styled.div`
  padding-top: 10px;
`;

class ContactForm extends PureComponent {
  get initialValues() {
    return fields
      .map((f) => ({ [f.name]: f.default }))
      .reduce((total, f) => ({ ...total, ...f }));
  }

  get validationSchema() {
    const validations = fields
      .map(({ name, validation }) => ({ [name]: validation }))
      .reduce((total, f) => ({ ...total, ...f }));
    return Yup.object().shape(validations);
  }

  onSubmit = (values) => {
    const { onClose } = this.props;
    contactActions.send(values);
    onClose();
  }

  onClose = () => {
    const { onClose } = this.props;
    notificationActions.add({ showAsSnackBar: true, severity: severities.info, message: 'Closed the form' });
    onClose();
  }

  render() {
    const { initialValues, validationSchema, onSubmit, onClose } = this;
    return (
      <Container>
        <Formik {...{ initialValues, validationSchema, onSubmit }}>
          {({ errors, touched }) => (
            <StyledForm>
              {fields.map(({ name, label, type, multiline, rows }, i) => {
                const component = TextField;
                return (
                  <div {...{ key: `${name}-wrapper`, className: 'form-group' }}>
                    <Field {...{ name, label, type, component, multiline, rows, fullWidth: true, className: `form-control${errors[i] && touched[i] ? ' is-invalid' : ''}` }} />
                  </div>
                );
              })}
              <MuiThemeProvider {...{ theme: matUiTheme }}>
                <ButtonContainer {...{ className: 'form-group' }}>
                  <Button {...{ key: 'submit', type: 'submit', variant: 'outlined' }}>Send</Button>
                  <Button {...{ key: 'reset', type: 'reset', variant: 'outlined' }}>Reset</Button>
                  <Button {...{ key: 'close', type: 'button', color: 'primary', variant: 'outlined', onClick: onClose }}>Close</Button>
                </ButtonContainer>
              </MuiThemeProvider>
            </StyledForm>
          )}
        </Formik>
      </Container>
    );
  }
}

export default ContactForm;
