import { createMuiTheme } from '@material-ui/core/styles';

const media = {
  small: 480,
  medium: 720,
  large: 960,
};

const createDummy = () => {
  const dummy = document.createElement('div');
  dummy.style.display = 'none';
  document.body.appendChild(dummy);
  return dummy;
};

const colors = {
  adingRed: 'rgb(128, 0, 0)',
  white: '#FFFFFF',
  green: '#00FF00',
  text: 'rgb(32, 32, 32)',
  textDimmed: 'rgb(128, 128, 128)',
  textEdit: 'rgb(32, 64, 128)',
  shadowLight: 'rgb(176, 176, 176);',
  background: 'rgb(255, 255, 255)',
};

const matUiTheme = createMuiTheme({
  palette: {
    primary: { main: colors.adingRed },
    secondary: { main: colors.white },
    third: { main: colors.black },
    affermative: { main: colors.green },
  },
  typography: { useNextVariants: true },
});

const setAlpha = (color, alpha) => {
  const dummy = createDummy();
  dummy.style.color = colors[color] || color;
  const rgbColor = window.getComputedStyle(dummy).color;
  document.body.removeChild(dummy);
  return rgbColor.replace('rgb', 'rgba').replace(')', `, ${alpha})`);
};

const setScale = (size, scale) => {
  const dummy = createDummy();
  dummy.style.fontSize = size;
  const pxSize = window.getComputedStyle(dummy).fontSize;
  document.body.removeChild(dummy);
  return `${parseInt(pxSize.replace('px'), 10) * scale}px`;
};

export default {
  media,
  colors,
  setAlpha,
  setScale,
  matUiTheme,
};

export {
  media,
  colors,
  setAlpha,
  setScale,
  matUiTheme,
};
