/* eslint-disable max-classes-per-file */
// TODO : Move to different class

import React, { Component, PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core';
import { colors, setAlpha, setScale } from '../../constants/theme';

const Button = styled.button`
    cursor: pointer;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.75;
    text-transform: uppercase;
    transition: all 0.5s ease;
    &.outlined {
      color: ${(props) => props.color};
      border: 1px solid ${(props) => props.color};
      border-radius: 4px;
      padding: 5px 15px;
    }
    &.contained {
      color: ${(props) => props.color};
      background-color: ${(props) => setAlpha(props.color, 0.4)};
      box-shadow: 0px 3px 1px -2px ${(props) => setAlpha(props.color, 0.2)},
        0px 2px 2px 0px ${(props) => setAlpha(props.color, 0.14)},
        0px 1px 5px 0px ${(props) => setAlpha(props.color, 0.12)};
      padding: 5px 15px;
    }
    &:hover {
      background-color: ${(props) => setAlpha(props.color, 0.3)};
    }
    &:active {
      box-shadow: 0px 2px 1px 2px ${(props) => setAlpha(props.color, 0.2)},
        0px 0px 5px 4px ${(props) => setAlpha(props.color, 0.12)};
    }
    &:focus {
      outline: transparent;
    }
`;

const Container = styled.div.attrs(({ }))`
  background-color: ${(props) => setAlpha(props.color, 0.4)};
  border-radius: ${(props) => props.fontSize};
  box-shadow: 0px 3px 1px -2px ${(props) => setAlpha(props.color, 0.2)},
    0px 2px 2px 0px ${(props) => setAlpha(props.color, 0.14)},
    0px 1px 5px 0px ${(props) => setAlpha(props.color, 0.12)};
  color: ${(props) => props.color};
  display: flex;
  font-size: ${(props) => props.fontSize};
  height: ${(props) => setScale(props.fontSize, 2)};
  line-height: ${(props) => setScale(props.fontSize, 2)};
  margin: ${(props) => setScale(props.fontSize, 0.25)};
  vertical-align: middle;
  width: fit-content;
  & span {
    padding-left: ${(props) => setScale(props.fontSize, 0.5)};
    padding-right: ${(props) => setScale(props.fontSize, props.suffix ? 0 : 0.5)};
  }
  & i {
    color: ${(props) => props.color};
    cursor: pointer;
    font-size: ${(props) => setScale(props.fontSize, 1.2)};
    padding: ${(props) => setScale(props.fontSize, 0.4)};
  }
`;

class Chip extends PureComponent {
  static get propTypes() {
    return {
      color: PropTypes.string,
      onDelete: PropTypes.function,
      fontSize: PropTypes.string,
    };
  }

  static get defaultProps() {
    return {
      color: colors.text,
      onDelete: () => { },
      fontSize: '1rem',
    };
  }

  render() {
    const { children, onDelete, ...other } = this.props;
    return (
      <Container {...{ ...other, suffix: !onDelete }}>
        <span>{children}</span>
        { onDelete && <i {...{ className: 'fas fa-times-circle' }} />}
      </Container>
    );
  }
}

const StyledTextField = styled(TextField)`
  width: 200px;
  margin: "dense";
`;

class EditDialog extends Component {
  constructor(props) {
    const { localization, orgLabels } = props;
    super(props);
    this.state = {
      languages: localization.i18nToLabel
        .map((x) => x.language)
        .filter((x, index, self) => self.indexOf(x) === index),
      labels: localization.labels.map((x) => ({ ...x })),
      i18nToLabel: localization.i18nToLabel
        .filter((x) => orgLabels.map((y) => y.i18nId).includes(x.i18nId))
        .map((x) => ({ ...x })),
    };
  }

  onOkClick = () => {

  }

  render() {
    const { state: { i18nToLabel, labels, languages, onOkClick }, props: { id, orgLabels, onClose } } = this;
    return (
      <Dialog {...{ onClose, open: true, modal: true }}>
        <DialogTitle>Translate label</DialogTitle>
        <DialogContent>
          <div>
            { languages.map((l) => (
              <div {...{ key: l }}>
                <Chip {...{ color: colors.text }}>{l}</Chip>
                { orgLabels.map((ol) => {
                  const { labelId } = (i18nToLabel.find((x) => x.language === l && x.i18nId === ol.i18nId) || {});
                  const defaultValue = (labels.find((x) => x.id === labelId) || { content: ol.content }).content;
                  const key = `[${l}] - ${ol.i18nId.replace(new RegExp(`${id}\\.{0,1}`, 'g'), '') || 'content'}`;
                  return <StyledTextField {...{ key, label: key, defaultValue, variant: 'outlined' }} />;
                }) }
              </div>
            )) }
            <li>Add</li>
          </div>
        </DialogContent>
        <DialogActions>
          <Button {...{ className: 'outlined', color: colors.adingRed, onClick: onClose }}>Cancel</Button>
          <Button {...{ className: 'contained', color: colors.text, onClick: onOkClick }}>Update</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default EditDialog;
