import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { Menu } from '../navigation';
import { colors, media } from '../../constants/theme';
import { companySmall } from '../../assets/images';
import Modal from './modal';
import ContactForm from './contact/contactForm';

const Container = styled.div`
  background-color: white;
  border-bottom: 1px solid white;
  height: 54px;
  padding: 10px 0px;
  position: fixed;
  top: 0;
  transition: all 0.5s ease;
  width: 100vw;
  z-index: 999;
  @media (min-width: ${media.small}px) {
    height: 66px;
  }
  @media (min-width: ${media.medium}px) {
    padding: 15px 0px;
    height: 80px;
  }
  &[data-scrolling='true'] {
    border-bottom: 1px solid ${colors.textDimmed};
    box-shadow: 0px 1px 2px -1px ${colors.textDimmed};
    padding: 0;
  }
`;

const Company = styled.a`
  color: ${colors.text};
  display: inline-flex;
`;

const Logo = styled.img`
  object-fit: contain;
  height: 100%;
  width: 120px;
  margin-left: 10px;
  @media (min-width: ${media.small}px) {
    width: 140px;
  }
  @media (min-width: ${media.medium}px) {
    margin-left: 30px;
    width: 180px;
  }
  @media (min-width: ${media.large}px) {
    margin-left: 50px;
  }
`;

const Contact = styled.a`
  background-color: ${colors.text};
  color: ${colors.background};
  font-size: 0.75rem;
  letter-spacing: 0.1rem;
  font-weight: 300;
  padding: 12px 25px;
  position: absolute;
  top: 50%;
  right: 50px;
  text-decoration: none;
  text-transform: uppercase;
  transform: translateY(-50%);
  transition: opacity 0.5s ease;
  &:hover {
    opacity: 75%;
    transition: opacity 0.5s ease;
  }
  @media (max-width: ${media.large - 1}px) {
    display: none;
  }
`;

class Header extends PureComponent {
  render() {
    const { props: { routes, hash, scrolling, contactOpen, toggleContact } } = this;
    return (
      <Container {...{ 'data-scrolling': scrolling }}>
        <Company {...{ id: 'home', href: '#/' }}>
          <Logo {...{ src: companySmall, alt: 'ading bv' }} />
        </Company>
        <Menu {...{ routes, hash }} />
        <Contact {...{ onClick: toggleContact }}>Contact us</Contact>
        <Modal {...{ open: contactOpen, onClose: toggleContact, title: 'Contact Us!', content: <ContactForm {...{ onClose: toggleContact }} /> }} />
      </Container>
    );
  }
}

export default Header;
