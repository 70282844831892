import store from '../store';
import { actions, severities } from '../constants';

const add = (data) => store.dispatch({ type: actions.notification.add, data });

const handle = (id) => store.dispatch({ type: actions.notification.handle, data: { id } });

const wrapMethod = (method) => (args) => {
  const notification = { showAsSnackBar: true };
  return method(args)
    .then(() => add({ ...notification, severity: severities.success, message: 'API call successful' }))
    .catch((ex) => {
      let { message } = ex;
      if (ex.status) {
        message = `${ex.status} ${ex.statusText || ex.rawResponse}`;
      }
      return add({ ...notification, severity: severities.error, message, description: ex.message });
    });
};

const wrapper = (toWrap) => {
  // to wrap functions
  if (typeof toWrap === 'function') {
    return wrapMethod(toWrap);
  }
  // to wrap object classes
  if (toWrap !== null && typeof toWrap === 'object') {
    const wrapped = {};
    // for each function property
    Object.keys(toWrap).forEach((x) => {
      if (Object.prototype.hasOwnProperty.call(toWrap, x) && typeof toWrap[x] === 'function') {
        wrapped[x] = wrapMethod(toWrap[x]);
      }
    });
    return wrapped;
  }
  return toWrap;
};

export default {
  wrapper,
  wrapMethod,
  add,
  handle,
};
