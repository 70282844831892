import React, { Component } from 'react';
import { Button } from '@material-ui/core';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import changeLogMd from '../../assets/CHANGELOG.md';

const Container = styled.div`
  padding: 2rem;
`;

const ButtonWrapper = styled.div`
    align-self: center;
    text-align:center;
    align-content: center;
`;

class Changelog extends Component {
  constructor(props) {
    super(props);
    this.state = { source: null };
  }

  componentDidMount() {
    fetch(changeLogMd)
      .then((response) => response.text())
      .then((text) => this.setState({ source: text }));
  }

  render() {
    const { onClose } = this.props;
    const { source } = this.state;
    return (
      <Container>
        <ReactMarkdown {...{ source }} />
        <ButtonWrapper>
          <Button {...{ onClick: onClose, variant: 'outlined' }}>Close</Button>
        </ButtonWrapper>
      </Container>
    );
  }
}

export default Changelog;
