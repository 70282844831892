import { combineReducers } from 'redux';
import localization from './localization';
import navigation from './navigation';
import profiles from './profiles';
import notification from './notifications';
import blog from './blog';

export default combineReducers({
  localization,
  profiles,
  blog,
  notification,
  navigation,
});
