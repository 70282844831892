import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { localizationActions } from '../../actions';
import { colors } from '../../constants/theme';
import EditDialog from './editDialog';

const Wrap = styled.span`
  border-radius: 4px;
  border: 1px solid ${colors.textEdit};
  display: inline-block;
  margin: -2px;
  padding: 1px;
  font-size: ${(props) => (props.hideWhiteSpace ? '0' : 'inherit')}
`;

const EditIcon = styled.i.attrs(() => ({ className: 'fas fa-pen-square' }))`
  color: ${colors.textEdit};
  cursor: pointer;
  font-size: 1.25rem;
  opacity: 0.5;
  position: absolute;
  transform: translate(-10px, -10px);
  transition: all 0.5s ease;
  &:hover {
    opacity: 1;
  }
`;

const I18n = connect((state) => ({ localization: state.localization }))(class I18n extends Component {
  constructor(props) {
    super(props);
    const { attrs, id, model } = this.props;
    if (attrs && attrs.length !== 0) {
      attrs.forEach((a) => localizationActions.add(`${id}.${a}`, model));
    } else {
      localizationActions.add(id, model);
    }
    this.state = {
      showEdit: false,
    };
  }

  onClick = (e) => {
    this.setState({ showEdit: true });
    e.preventDefault();
    e.stopPropagation();
  };

  handleCloseEdit = () => this.setState({ showEdit: false });

  render() {
    const { onClick, state: { showEdit }, props: { attrs, id, children, localization, model } } = this;
    let child;
    let orgLabels;
    let hideWhiteSpace = false;
    switch (typeof (children)) {
      case 'function':
      {
        const orgAttr = children().props;
        const other = (attrs || [])
          .map((a) => ({ [a]: localization.get(`${id}.${a}`, model, orgAttr[a]) }))
          .reduce((o, a) => ({ ...o, ...a }));
        orgLabels = (attrs || []).map((a) => ({ i18nId: `${id}.${a}`, content: orgAttr[a] }));
        child = children({ ...other });
        hideWhiteSpace = true;
        break;
      }
      case 'string':
        orgLabels = [{ i18nId: id, content: children }];
        child = localization.get(id, model, children);
        break;
      default:
        // eslint-disable-next-line no-console
        console.error('Impossible to pass component(s) as children! Please wrap maximum one component as function');
        return children;
    }
    if (!localization.editMode) {
      return child;
    }
    return [
      <EditIcon {...{ key: 'icon', onClick }} />,
      <Wrap {...{ key: 'wrap', hideWhiteSpace }}>{child}</Wrap>,
      (showEdit ? <EditDialog {...{ key: 'dialog', id, orgLabels, localization, onClose: this.handleCloseEdit }} /> : null),
    ];
  }
});

export default {
  I18n,
};

export {
  I18n,
};
