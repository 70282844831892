import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import Title from '../layout/title';
import { avatar } from '../../assets/images';
import { media } from '../../constants/theme';

const PersonCard = styled.div`
  float: left;
  width: 23%;
  align-items: center;
  padding: 5%;
`;

const Picture = styled.img`
  margin-bottom: 40px;
  background-color: #f4f4f4;
  width: 100%;
  height: auto;
  transition: all 0.75s ease;
`;

const Description = styled.div`
  margin-bottom: 60px;
  align-content: center;
  align-items: center;
  align-text: center;
`;

const Function = styled.div`
  opactity: 0.6
`;

const CenteredText = styled.p`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all 0.5s ease;
`;

const PersonImage = styled.div`
  position: relative;
  text-align: center;
  color: white;
  &:hover ${CenteredText}{
    opacity: 1;
  }
  &:hover ${Picture}{
    filter: blur(4px) brightness(0.5) grayscale(1);
  }
`;

const StyledLink = styled.a`
  color: white;
  text-decoration: inherit;
`;

const SkillTitle = styled.div`
  width: 15%;
  font-weight: bold;
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  align-items: flex-start;
  position: relative;
`;

const UnstyledList = styled.ul`
  list-style: none;
  padding-inline-start: 0;
  padding-left: 0;
`;

const SkillItem = styled.div`
  padding: 10px;
`;

const TeamContainer = styled.div`
  width: inherit;
  clear: both;
  height; inherit;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: flex-start;
  @media (max-width: ${media.small}px) {
    flex-direction: column;
    margin-top: 10px;
  }
`;

class Team extends PureComponent {
  render() {
    const { props: { profiles, skills } } = this;
    return (
      <Container>
        <TeamContainer>
          <Title {...{ title: 'Meet the people behind ading bv', description: 'Working at ading is not just working. It\'s sharing a vision!' }} />
          { profiles?.map(({ key, basics, owner, isDummy }) => (
            <PersonCard>
              <PersonImage>
                <StyledLink {...{ href: !isDummy ? `https://registry.jsonresume.org/${key}` : 'mailto:info@ading.be' }}>
                  <Picture {...{ src: basics.image || avatar }} />
                  <CenteredText>{ owner ? 'Check out the resume.' : 'Contact us !'}</CenteredText>
                </StyledLink>
              </PersonImage>
              <Description>
                <div>{basics.name}</div>
                <Function>{basics.label}</Function>
              </Description>
            </PersonCard>
          ))}
          { !profiles && <p>An error occured during getting the team. Please try again later.</p>}
        </TeamContainer>
        <Title {...{ title: 'Some of ours skills.', description: 'Our skillset is always expanding.' }} />
        <FlexContainer>
          { skills && Object.entries(skills).map(([title, value]) => (
            <SkillItem>
              <SkillTitle>{title}</SkillTitle>
              { value.filter((v, i, a) => a.indexOf(v) === i).map((z) => (
                <UnstyledList>{z}</UnstyledList>
              )) }
            </SkillItem>
          ))}
        </FlexContainer>
      </Container>
    );
  }
}

export default connect((state) => state.profiles)(Team);
