import React from 'react';
import styled from 'styled-components';
import sha256 from 'crypto-js/sha256';
import { colors } from '../../constants/theme';

const Wrap = styled.p`
  color: ${(props) => (props.color || colors.textDimmed)};
`;

const Paragraph = ({ children }) => {
  if (typeof children === 'string') {
    return children.split('\r\n').map((x) => (<Wrap {...{ key: sha256(x) }}>{x}</Wrap>));
  }
  return <Wrap>{children}</Wrap>;
};

export default Paragraph;
