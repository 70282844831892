import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { colors, media } from '../../constants/theme';

const Link = styled.a`
  color: ${colors.text};
  font-size: 0.75rem;
  font-weight: 500;
  letter-spacing: 0.1rem;
  opacity: 50%;
  padding: 8px 20px;
  text-decoration: none;
  text-transform: uppercase;
  transition: opacity 0.5s ease;
  &[data-active=true] {
    opacity: 100%;
    font-weight: 700;
  }
  &:hover {
    opacity: 80%;
    transition: opacity 0.5s ease;
  }
  @media (max-width: ${media.large - 1}px) {
    display: block;
    padding: 16px 20px;
    text-align: center;
    &:hover {
      background-color: ${colors.shadowLight};
      transition: background-color 0.5s, opacity 0.5s ease;
    }
  }
`;

class MenuItem extends PureComponent {
  get isActive() {
    const { hash, href } = this.props;
    return hash === href;
  }

  onClick = () => {
    const { onClick } = this.props;
    if (onClick && !this.isActive) {
      onClick();
    }
  }

  render() {
    const { isActive, onClick } = this;
    const { href, name } = this.props;
    return (<Link {...{ key: href, href, 'data-active': isActive, onClick }}>{name}</Link>);
  }
}

export default MenuItem;
