// Alternate images
// about => https://images.unsplash.com/photo-1492541641671-bd75cf01a094
// blog => https://images.unsplash.com/photo-1582282576858-1d0edf30f9a4
const defaultState = {
  routes: [
    { name: 'Home', title: 'More Information', href: '/' },
    { name: 'About',
      title: 'About us',
      href: '#/about',
      image: 'https://images.unsplash.com/photo-1568883608988-a4ac2e80e121' },
    { name: 'Work',
      title: 'Our work',
      href: '#/work',
      image: 'https://images.unsplash.com/photo-1503408429278-71c29911c837' },
    { name: 'Team',
      title: 'The team',
      href: '#/team',
      image: 'https://images.unsplash.com/photo-1577738596668-5399652e3130' },
    { name: 'Blog',
      title: 'What\'s up?',
      href: '#/blog',
      image: 'https://images.unsplash.com/photo-1588238323176-f824801ac617' },
    { name: 'Contact',
      title: 'Get connected!',
      href: '#/contact',
      image: 'https://images.unsplash.com/photo-1571840855309-c9611d7a88be' },
  ],
};

const navigation = (state = defaultState, { type }) => {
  switch (type) {
    default:
      return state;
  }
};

export default navigation;
