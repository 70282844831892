import './assets/css/index.css';
import './assets/font-awesome/css/fontawesome.css';
import './assets/font-awesome/css/regular.css';
import './assets/font-awesome/css/solid.css';
import 'core-js';
import React, { Component } from 'react';
import { animateScroll } from 'react-scroll';
import ReactDOM from 'react-dom';
import { connect, Provider } from 'react-redux';
import { HashRouter, Route, Switch, withRouter } from 'react-router-dom';
import store from './store';
import { blogActions, profileActions } from './actions';
import { Content, Header, Footer, Page } from './components/layout';
import Pages from './components/pages';

const ScrollToBottom = () => {
  animateScroll.scrollToBottom({
    containerId: 'footer',
  });
};

const Routes = withRouter(connect((state) => state.navigation)(({ routes, scrolling, changeLogOpen, contactOpen, toggleContact }) => {
  const { hash } = window.location;
  const route = routes.find((x) => x.href === window.location.hash);
  const RouteContent = Pages[(route || { name: 'Home' }).name] || (() => <div />);

  return (
    routes.map(({ href }) => (
      <Route {...{ key: href,
        path: href.replace('#/', ''),
        render: () => (
          <Page>
            <Header {...{ routes, hash, scrolling, changeLogOpen, contactOpen, toggleContact }} />
            <Content {...{ route, RouteContent, ScrollToBottom }} />
            <Footer />
          </Page>
        ) }}
      />
    ))
  );
}));

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scrolling: false,
      changeLogOpen: false,
      contactOpen: false,
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    profileActions.load();
    blogActions.load();
  }

  // Remove the event listener when the component is unmount.
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  toggleLog = () => {
    this.setState((prevState) => ({
      changeLogOpen: !prevState.changeLogOpen,
    }));
  };

  toggleContact = () => {
    this.setState((prevState) => ({
      contactOpen: !prevState.contactOpen,
    }));
  };

  // Hide or show the menu.
  handleScroll = () => this.setState({ scrolling: window.pageYOffset > 20 });

  render() {
    const { state: { scrolling, changeLogOpen, contactOpen }, toggleContact, toggleLog } = this;
    return (
      <HashRouter>
        <Switch>
          <Routes {...{ scrolling, changeLogOpen, contactOpen, toggleContact, toggleLog }} />
        </Switch>
      </HashRouter>
    );
  }
}

ReactDOM.render(<Provider {...{ store }}><App /></Provider>, document.getElementById('main'));

if (module.hot) {
  module.hot.accept();
}
