import code from './code.jpg';
import companySmall from './companySmall.png';
import logo from './logoIcon.png';
import menu from './menu.png';
import tesla from './tesla.jpg';
import avatar from './avatar.jpg';

import ing from './clients/ing.jpg';
import mtx from './clients/mtx.jpg';
import oniva from './clients/oniva.png';

export default {
  code,
  companySmall,
  logo,
  menu,
  tesla,
  avatar,

  ing,
  mtx,
  oniva,
};

export {
  code,
  companySmall,
  logo,
  menu,
  tesla,
  avatar,

  ing,
  mtx,
  oniva,
};
