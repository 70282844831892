import React from 'react';
import styled from 'styled-components';
import { contactData } from '../../constants';
import { I18n } from '../i18n';
import { media } from '../../constants/theme';
import Map from '../layout/contact/map';

const Container = styled.div`
  width: 100%;
  max-width: 1140px;
  margin-right: auto;
  margin-left: auto;
`;

const ContactGrid = styled.div`
  margin-top: 100px;
  margin-bottom: 100px;
  align-items: start;
  grid-column-gap: 80px;
  grid-row-gap: 30px;
  grid-template-areas: ". .";
  grid-template-columns: 2.5fr 1fr;
  grid-template-rows: auto;
`;

const LeftColumn = styled.div`
  grid-column-start: 1;
  float:left;
  margin-left: 10px;
  grid-column-end: 2;
  grid-row-start: 1;
  padding-right: 40px;
  width: 70%;
  grid-row-end: 2;
  @media (max-width: ${media.small}px) {
   width: 100%;
  }
  @media (max-width: ${media.medium}px) {
    width: 100%;
    }
  }
`;

const RightColumn = styled.div`
  grid-column-start: 2;
  grid-column-end: 3;
  float:left;
  width: 25%;
  grid-row-start: 1;
  grid-row-end: 2;
  @media (max-width: ${media.small}px) {
    width: 100%;
    margin-bottom: 25px;
   }
   @media (max-width: ${media.medium}px) {
     width: 100%;
     padding-bottom: 300px;
     }
   }
`;

const Label = styled.div`
  margin-bottom: 10px;
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
`;

const Contact = () => {
  const { vat, mail, phone, address } = contactData;
  return (
    <Container>
      <ContactGrid>
        <div>
          <LeftColumn>
            <Map />
          </LeftColumn>
          <RightColumn>
            <div>
              <Label><I18n {...{ id: 'contact.ourOffice' }}>OUR OFFICE</I18n></Label>
              <p>
                {address}
              </p>
            </div>
            <div>
              <Label><I18n {...{ id: 'contact.contactUs' }}>CONTACT US</I18n></Label>
              <p>
                { vat }
                <br />
                { mail }
                <br />
                { phone }
              </p>
            </div>
          </RightColumn>
        </div>
      </ContactGrid>
    </Container>
  );
};

export default Contact;
