import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import dayjs from 'dayjs';
import Title from '../layout/title';
import BlogItem from '../controls/blogItem';

const Container = styled.div`
  width: 100%;
  max-width: 1140px;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 5%
  margin-bottom: 40px;
`;

const Blog = ({ items }) => (
  <Container>
    <Title {...{ title: 'Our stories', description: 'Follow our journeys through the software development landscape.' }} />
    { (items || [])
      .sort((el1, el2) => el2.created - el1.created)
      .map((x) => <BlogItem {...{ ...x, subTitle: dayjs(x.created).format('DD/MM/YYYY') }} />) }
  </Container>
);

export default (connect((state) => (state.blog))(Blog));
