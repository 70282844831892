import { createStore } from 'redux';
import reducers from './reducers';

const initialState = {};

const store = createStore(
  reducers,
  initialState,
  typeof window !== 'undefined' ? window.devToolsExtension && window.devToolsExtension() : undefined,
);

if (module.hot) {
  // Enable Webpack hot module replacement for reducers
  module.hot.accept('./reducers', () => store.replaceReducer(reducers));
}

export default store;
