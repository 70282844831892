import React from 'react';
import styled from 'styled-components';
import { tesla, code } from '../../assets/images';
import Title from '../layout/title';
import BlogItem from '../controls/blogItem';

const items = [
  { key: 'vision',
    title: 'SOLID code solves complex issues',
    subTitle: 'Vision',
    description: 'Ading stands for quality. Beautiful and strong code is not just what we represent, it\'s what we breathe.',
    image: code },
  { key: 'mission',
    title: 'The future is now',
    subTitle: 'Mission',
    description: 'Ading stands for green and positivity. We believe in an ecological and green future.',
    image: tesla },
];

const Container = styled.div`
  width: 100%;
  max-width: 1140px;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 5%
  margin-bottom: 40px;
`;

const About = () => (
  <Container>
    <Title {...{ title: 'Mission statement', description: 'Aside our professional values and work ethics, we also strive to make a change.' }} />
    {items.map((x) => (
      <BlogItem {...x} />
    ))}
  </Container>
);

export default About;
