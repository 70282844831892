import store from '../store';
import { actions } from '../constants';
import logo from '../assets/video/logo.gif';
import cypress from '../assets/images/cypress.gif';
import safe from '../assets/images/safe.png';

const data = [{
  key: 0,
  title: 'www.ading.be is online',
  created: new Date(2020, 4, 20),
  description: 'Our company website is back online. '
    + 'With the corona virus upon us, we found time in our otherwise busy schedule to start building our website with our favorite technologies. \r\n'
    + 'As you probably noticed, the work is not yet complete, so visit us from time to time to see the progress. \r\n'
    + 'We\'ll add some easter eggs in the future ...',
  link: 'http://www.ading.be',
  image: logo,
}, {
  key: 1,
  title: 'A modern site requires modern testing.',
  created: new Date(2020, 4, 29),
  description: 'That\'s why we have gone with Cypress testing. '
        + 'Cypress is a standalone testing framework, that allows for beautiful and automated end to end testing.'
        + 'Neither of us had experience with the framework, but setting it up was relatively easy.'
        + 'Once again, we expand our set of skills, with the aid of the fantastic open-source community.',
  link: 'http://www.cypress.io',
  image: cypress,
}, {
  key: 2,
  title: 'Stay safe! Stay in your kot!',
  created: new Date(2020, 8, 22),
  description: 'The corona virus won\'t hold us back. '
        + 'In these special times, we are always looking for a solution. '
        + 'Working from home has become the standard, and at ADING we encourage it.\r\n'
        + 'But that doesn\'t mean taking it easy! '
        + 'We are busy developing a coroporate project management site for Deloitte.\r\n'
        + 'Security is import in an application, but it\'s also important for human health in times of a global crisis.\r\n'
        + 'Stay safe. Stay secure. Stay at home.',
  image: safe,
}];

const load = () => {
  store.dispatch({ type: actions.blog.load, data });
};

export default {
  load,
};
