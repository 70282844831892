export default {
  localization: {
    add: 'ADD_I18N',
    load: 'LOAD_I18N',
  },
  profile: {
    load: 'LOAD_PROFILES',
    skills: 'LOAD_SKILLS',
  },
  blog: {
    load: 'LOAD_BLOGS',
  },
  notification: {
    add: 'NOTIFICATION-ADD',
    handle: 'NOTIFICATION-HANDLED',
  },
};
