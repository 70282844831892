import Home from './home';
import Contact from './contact';
import About from './about';
import Work from './work';
import Team from './team';
import Blog from './blog';

export default {
  Home,
  About,
  Contact,
  Work,
  Team,
  Blog,
};

export {
  Home,
  About,
  Contact,
  Work,
  Team,
  Blog,
};
