import { actions } from '../constants';
import { avatar } from '../assets/images';

const initialState = {
  profiles: [
    { basics: { name: 'You ?', label: 'Awesome teammember to be ? ', description: 'Apply now !', image: avatar }, skills: [], key: 'YOU', isDummy: true }],
  skills: [],
};

const profileReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case actions.profile.load:
    {
      const profiles = [...data, ...state.profiles];
      const skills = profiles
        .map(({ skills: s }) => ([...s]) || []) // [[], []]
        .reduce((all, p) => ([...all, ...p])) // flat
        .map(({ name, keywords }) => ({ [name]: keywords }))
        .reduce((all, s) => {
          const name = Object.keys(s)[0];
          return { ...all, [name]: [...(all[name] || []), ...s[name]] };
        });
      return { ...state, profiles, skills };
    }
    default:
      return state;
  }
};

export default profileReducer;
