import { actions } from '../constants';

const initialState = {
  items: [],
};

const blog = (state = initialState, action) => {
  switch (action.type) {
    case actions.blog.load:
    {
      const keys = action.data.map((x) => x.key);
      return { ...state, items: [...state.items.filter((x) => !keys.includes(x.key)), ...action.data] };
    }
    default:
      return state;
  }
};

export default blog;
