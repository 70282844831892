import { actions } from '../constants';

const getInitialState = () => {
  const { language, userLanguage } = window.navigator;
  return {
    languages: ['en'],
    userLanguage: language || userLanguage,
    editMode: false,
    i18n: {},
    labels: [{
      id: '83v9pf8p8x',
      content: 'Powered by © ading bv [year:2000] - Version @ [version]',
    }, {
      id: '9211d292e4',
      content: 'ading bv',
    }],
    i18nToLabel: [{
      i18nId: 'footer.poweredBy',
      labelId: '83v9pf8p8x',
      language: 'en',
    }, {
      i18nId: 'footer.poweredBy.logo.title',
      labelId: '9211d292e4',
      language: 'nl-BE',
    }],
    get(id, model, orgLabel) {
      let label = this.i18n[id] || orgLabel;
      // Replace with members from model
      const regex = /\[([^:\]]+):{0,1}([^:\]]*)\]/gm;
      let match = regex.exec(label);
      while (match !== null) {
        // get's matching member or the default value, if any provide '[member:default]'
        const replacement = (model || {})[match[1]] || match[2];
        label = label.replace(match[0], replacement);
        regex.lastIndex = 0;
        match = regex.exec(label);
      }
      return `${(!this.i18n[id] && this.editMode ? `[${this.userLanguage}]` : '')}${label}`;
    },
  };
};

const localization = (state = getInitialState(), action) => {
  switch (action.type) {
    case actions.localization.add:
    {
      const { userLanguage } = state;
      const { id } = action.data;
      const labelIds = state.i18nToLabel.filter((x) => x.i18nId === id);
      const { labelId } = (labelIds.find((x) => x.language === userLanguage)
        || labelIds.find((x) => x.language === userLanguage.split('-')[0])
        || labelIds.find((x) => !x.language)
        || {});
      const label = (state.labels.find((x) => x.id === labelId) || {}).content;
      return { ...state, i18n: { ...state.i18n, [id]: label } };
    }
    default:
      return state;
  }
};

export default localization;
