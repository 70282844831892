import React from 'react';
import styled from 'styled-components';
import { colors, media } from '../../constants/theme';

const Container = styled.div`
  align-self: stretch;
  display: inline-block;
  text-align: center;
  vertical-align: top;
  width: 100%;
  @media (min-width: ${media.small}px) {
    text-align: left;
  }
  @media (min-width: ${media.medium}px) {
    width: 30%;
  }
  @media (min-width: ${media.large}px) {
  }
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 400;
  line-height: 34px;
  margin-bottom: 10px;
  opacity: 1;
`;

const Description = styled.div`
  color: ${colors.textDimmed};
`;

const Picture = styled.img`
  width:50px;
  height:50px;
  margin-top: 25px;
`;

const GridItem = ({ icon, title, description }) => (
  <Container>
    <Picture {...{ src: icon, alt: icon }} />
    <Title>{title}</Title>
    <Description>{description}</Description>
  </Container>
);

export default GridItem;
