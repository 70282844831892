import shortGuid from 'short-uuid';
import { actions } from '../constants';

// Example for notification object
// {
//   createDate: null,
//   description: null,
//   id: shortGuid.generate(),
//   isHandled: true,
//   message: '',
//   showAsSnackBar: false,
//   type: notificationTypes.info,
// };

const createNotification = (data) => (
  {
    ...data,
    autoHideDuration: 3000,
    id: shortGuid.generate(),
    isHandled: false,
    showAsSnackBar: true,
    createDate: new Date(),
  }
);

const initialState = { notifications: [] };

export default (state = initialState, { type, data }) => {
  switch (type) {
    case actions.notification.add:
      return { ...state, notifications: [...state.notifications, createNotification(data)] };
    case actions.notification.handle: {
      const notification = state.notifications.find((x) => x.id === data.id);
      if (notification) {
        const notifications = [...state.notifications
          .filter((x) => x.id !== data.id), { ...notification, isHandled: true }]
          .sort((el1, el2) => el1.createDate - el2.createDate);
        return { ...state, notifications };
      }
      return state;
    }
    default:
      return state;
  }
};
