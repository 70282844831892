/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import styled from 'styled-components';
import Title from '../layout/title';
import GridItem from '../controls/gridItem';
import { colors, media, setAlpha } from '../../constants/theme';
import logo from '../../assets/video/logo.gif';
import items from '../../constants/grid';

const HomeHeader = styled.div`
  align-items: center;
  background-color: ${colors.text};
  background-image: url('${logo}');
  background-position: center;
  background-size: cover;
  color: #fff;
  display: flex;
  height: 140px;
  justify-content: center;
  margin-bottom: 100px;
  & i {
    font-size: 1rem;
    padding: 0.6rem;
    top: 114px;
  }
  @media (min-width: ${media.small}px) {
    height: 280px;
    & i {
      font-size: 2rem;
      padding: 0.6rem;
      top: 240px;
    }
  }
  @media (min-width: ${media.medium}px) {
    height: 420px;
    & i {
      font-size: 3rem;
      padding: 0.6rem;
      top: 360px;
    }
  }
  @media (min-width: ${media.large}px) {
    height: 560px;
    & i {
      font-size: 4rem;
      padding: 0.8rem;
      top: 480px;
    }
  }
`;

const Slogan = styled.div`
  background: ${setAlpha('text', 0.4)};
  color: ${colors.background};
  display: block;
  font-size: 2rem;
  padding: 0.5rem;
  text-align: center;
  z-index: 2;
  & :first-child {
    font-weight: 700;
  }
  & :nth-child(2) {
    font-size: 0.8rem;
  }
  @media (min-width: ${media.small}px) {
    font-size: 3rem;
    & :nth-child(2) {
      font-size: 1.2rem;
    }
  }
  @media (min-width: ${media.medium}px) {
    font-size: 4rem;
    & :nth-child(2) {
      font-size: 1.6rem;
    }
  }
  @media (min-width: ${media.large}px) {
    font-size: 5rem;
    & :nth-child(2) {
      font-size: 2rem;
    }
  }
`;

const Scroll = styled.i.attrs(() => ({ className: 'fas fa-chevron-down' }))`
  cursor: pointer;
  left: calc(100vw * 0.5);
  position: absolute;
  transform: translateX(-50%);
  z-index: 2;
  @supports {
    left: auto;
  }
`;

const Services = styled.div`
  align-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: auto;
  padding-bottom: 50px;
  width: 80%;
  @media (max-width: ${media.medium - 1}px) {
    flex-direction: column;
    margin-top: 10px;
  }
`;

const Home = ({ ScrollToBottom }) => (
  <div>
    <HomeHeader>
      <Slogan>
        <div>We are a(d)ding</div>
        <div>value to your software solutions since 1999</div>
      </Slogan>
      <Scroll {...{ id: 'scroll-to-bottom', onClick: ScrollToBottom }} />
    </HomeHeader>
    <Title {...{ title: 'Analysis Development INtegration Group',
      description: 'For all your software development, testing and integration needs, we have a solution ready. Expertise combined with the latest technologies can create wonderful things.' }}
    />
    <Services>
      {items.map((item) => <GridItem {...item} />)}
    </Services>
  </div>
);

export default Home;
