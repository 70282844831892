import store from '../store';
import { actions } from '../constants';

const add = (id, model) => {
  store.dispatch({ type: actions.localization.add, data: { id, model } });
};

export default {
  add,
};
