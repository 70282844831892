import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import styled from 'styled-components';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

const Container = styled.div`
    width: 80%;
`;

const StyledDialog = styled(Dialog)({
  paddingLeft: '10px',
  paddingRight: '10px',
  paddingTop: '10px',
});

const StyledDialogTitle = styled(DialogTitle)`
  align-self: center;
  align-content: center;
`;

const Modal = ({ open, onClose, title, content }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Container>
      <StyledDialog {...{ open, fullWidth: true, modal: true, onClose, fullScreen }}>
        <StyledDialogTitle>{title}</StyledDialogTitle>
        {content}
      </StyledDialog>
    </Container>
  );
};

export default Modal;
