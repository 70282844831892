import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import sha256 from 'crypto-js/sha256';
import Title from '../layout/title';
import BlogItem from '../controls/blogItem';

const Container = styled.div`
  width: 100%;
  max-width: 1140px;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 5%
  margin-bottom: 40px;
`;

const Work = (props) => {
  const { profile } = props;
  const { work } = profile || { };
  return (
    <div>
      <Title {...{ title: 'Shows that we go that extra mile.',
        description: 'At ading bv we have done a lot of versatile projects already that might help you in achieving your software solution.' }}
      />
      <Container>
        { !work
          ? <p>Error getting experiences. Try again later.</p>
          : work.map((x) => {
            const { company: title, summary, website, highlights, image, startDate, endDate } = x;
            const from = dayjs(startDate).format('MMMM YYYY');
            const till = dayjs(endDate).format('MMMM YYYY');
            let subTitle;
            if (from === till) {
              subTitle = `During ${from}`;
            } else if (endDate) {
              subTitle = `From ${from} till ${till}`;
            } else {
              subTitle = `Started ${from}`;
            }
            const key = sha256(`${title}${subTitle}`).toString();
            return (
              <BlogItem {...{ key, title, subTitle, description: summary, link: website, bullets: highlights, image }} />
            );
          })}
      </Container>
    </div>
  );
};

export default (connect(
  (state) => ({ profile: (state.profiles.profiles.find((profile) => profile.key === 'BartVanHoutte-ADING')) }),
)(Work));
