import React, { PureComponent } from 'react';
import styled from 'styled-components';
import sha256 from 'crypto-js/sha256';
import Paragraph from './paragraph';
import { colors, media, setAlpha } from '../../constants/theme';

const SubTitle = styled.span`
  color: ${colors.textDimmed};
`;

const Title = styled.h2`
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 1.25rem;
  font-weight: 700;
`;

const Wrapper = styled.div`
  margin-bottom: 2rem;
  max-height: 20%;
  display: block;
  padding: 15px;
  @media (min-width: ${media.small}px) {
  }
  @media (min-width: ${media.medium}px) {
    padding: 30px;
    &:nth-child(2n) div {
      float: left;
      margin-right: 1rem;
    }
    &:nth-child(2n + 1) div {
      float: right;
      margin-left: 1rem;
      &
    }
    & h2 {
      font-size: 1.5rem;
      font-weight: 500;
    }
  }
  @media (min-width: ${media.large}px) {
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  & img {
    height: 240px;
    object-fit: cover;
    width: 100%;
  }
  @media (min-width: ${media.small}px) {
    & img {
      height: 340px;
    }
  }
  @media (min-width: ${media.medium}px) {
    display: inline;
    width: 60%;
    & img {
      height: 280px;
    }
  }
  @media (min-width: ${media.large}px) {
    & img {
      height: 360px;
    }
  }
`;

const OverlayLink = styled.a`
  background-color: ${setAlpha('text', 0.1)};
  height: calc(100% - 4px);
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
`;

const Bullets = styled.ul`
  display: flow-root;
`;

const defaultImage = 'https://lesilets.com/wp-content/uploads/2018/04/1096907-cool-dark-background-2560x1600-for-tablet.jpg';

class BlogItem extends PureComponent {
  static get defaultProps() {
    return {
      bullets: [],
      description: null,
      image: null,
      link: '/',
      orientation: undefined,
      subTitle: null,
      title: '',
    };
  }

  render() {
    const { title, subTitle, description, orientation, image, link, bullets } = this.props;
    return (
      <Wrapper>
        <ImageWrapper>
          <img {...{ src: image || defaultImage, orientation, alt: title }} />
          <OverlayLink {...{ href: link }} />
        </ImageWrapper>
        <SubTitle>{subTitle}</SubTitle>
        <Title>{title}</Title>
        <Bullets>
          {bullets.map((x) => <li {...{ key: sha256(x).toString() }}>{x}</li>)}
        </Bullets>
        <Paragraph>{description}</Paragraph>
      </Wrapper>
    );
  }
}

export default BlogItem;
