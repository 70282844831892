import React from 'react';
import styled from 'styled-components';
import { colors, media } from '../../constants/theme';
import SnackBar from '../controls/snackbar';

const Container = styled.div`
  margin-bottom: 2.5rem;
  position: relative;
  top: 80px;
  width:100vw;
  @media (min-width: ${media.small}px) {
    top: 90px;
  }
  @media (min-width: ${media.medium}px) {
    top: 120px;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.5;
  filter: blur(8px) grayscale(1);
  transition: all 0.75s ease;
  &:hover{
    opacity: 1;
    filter: blur(0) grayscale(0);
    transform: scale(1.1, 1.1);
  }
`;

const Title = styled.div`
  font-weight: 300;
  font-size: 1.5rem;
  padding: 10px 0;
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
  width: 70%;
  @media (min-width: ${media.small}px) {
    font-size: 2rem;
  }
  @media (min-width: ${media.medium}px) {
    font-size: 3rem;
  }
  @media (min-width: ${media.large}px) {
    font-size: 4rem;
  }
`;

const Media = styled.div`
  background-color: ${colors.text};
  color: ${colors.background};
  height: 180px;
  margin: 0 15px 20px 15px;
  overflow: hidden;
  position: relative;
  @media (min-width: ${media.small}px) {
    height: 270px;
    margin-bottom: 50px;
  }
  @media (min-width: ${media.medium}px) {
    height: 360px;
    margin: 0 30px 80px 30px;
  }
  @media (min-width: ${media.large}px) {
    height: 480px;
    margin-bottom: 100px;
  }
`;

const Content = ({ route, RouteContent, ScrollToBottom }) => (
  <Container>
    { route && (
      <Media>
        { route.image && <Image {...{ src: route.image }} />}
        <Title>{route.title}</Title>
      </Media>
    )}
    <RouteContent {...{ ScrollToBottom }} />
    <SnackBar />
  </Container>
);

export default Content;
