import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { colors, media } from '../../constants/theme';
import { menu } from '../../assets/images';
import MenuItem from './menuItem';

const Container = styled.div`
  left: 50%;
  overflow: hidden;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  @media (max-width: ${media.large - 1}px) {
    height: ${window.screen.availHeight}px;
    left: 0;
    top: 100%;
    width: 100%;
    transform: translate(0, 0);
    z-index: 9;
  }
`;

const Wrapper = styled.div`
  background-color: ${colors.background};
  @media (max-width: ${media.large - 1}px) {
    transform: translate(0, -100%);
    transition: transform 0.5s ease;
    &[data-show=true] {
      transform: translate(0, 0);
    }
  }
`;

const Icon = styled.img`
  cursor: pointer;
  height: 30px;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  width: 30px;
  @media (min-width: ${media.medium}px) {
    right: 30px;
  }
  @media (min-width: ${media.large}px) {
    display: none;
  }
`;

class Menu extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
  }

  onClick = () => this.setState((x) => ({ ...x, show: !x.show }));

  render() {
    const { onClick } = this;
    const { routes, hash } = this.props;
    const { show } = this.state;
    return [
      <Container {...{ key: 'menu-container' }}>
        <Wrapper {...{ key: 'menu', 'data-show': show }}>
          { routes
            .filter((x) => x.href !== '/')
            .map(({ href, name }) => (<MenuItem {...{ key: href, hash, href, onClick, name }} />)) }
        </Wrapper>
      </Container>,
      <Icon {...{ key: 'menu-icon', src: menu, alt: 'Menu', onClick }} />];
  }
}

export default Menu;
