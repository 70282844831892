import React from 'react';
import styled from 'styled-components';

const IFrameStyle = styled.div`
  width: 100%;
`;

const StyledIFrame = styled.iframe`
  width: 100%;
  height: 450px;
  scrolling: 'no';
  marginHeight: 0;
  marginWidth: 0;
  frameBorder: 0;
`;

const Map = () => (
  <div>
    <IFrameStyle>
      <StyledIFrame
        title="maps"
        src="https://maps.google.com/maps?hl=en&amp;coord=51.3152398,4.4484665&amp;q=Zinnialaan%2033+(Ading%20BV)&amp;ie=UTF8&amp;t=&amp;z=14&amp;iwloc=B&amp;output=embed"
      />
    </IFrameStyle>
    <br />
  </div>
);

export default Map;
